<script>
import Layout from "../../layouts/main";
import Filters from '../../../components/filters.vue';
import SearchBy from '../../../components/search-by.vue';
import DownloadBtn from '../../../components/download-btn.vue';
import PerPage from '../../../components/per-page.vue';
import Multiselect from 'vue-multiselect';
import { required } from "vuelidate/lib/validators";
import * as moment from "moment";
import Swal from "sweetalert2";

import {
	FETCH_INVOICES,
    EXPORT_INVOICES,
    FETCH_INVOICES_TEMPLATES,
    INVOICES_REMINDER,
    FETCH_MERCHANTS_LIST,
    DELETE_INVOICE_TEMPLATE,
    FETCH_SOURCE_LIST,
    FETCH_DETAILS_FOR_ALL_SUB_USERS,
    BULK_INVOICE_IMPORT
} from "@/state/actions.type";
import Common from "../../../helpers/Common";
export default {
    components: {
        Layout,
        Filters,
        SearchBy,
        DownloadBtn,
        PerPage,
        Multiselect
    },
    validations: {
        form: {
            template_name: { required },
        }
    },
    data() {
        return {
            searchBy: {
                key: "invoice_ref",
                value: ""
            },
            deteleTemplateData:{},
            form: {
                template_name: ""
            },
            create_new_template:false,
            createdByFilter: null,
            merchant_uuids:[],
            searchByOptions: ['Invoice Ref','Merchant Invoice Ref', 'Description', 'Customer Name', 'Customer Email', 'Customer Phone' ],
            isFetching: false,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            fields: [
                { key: "created_at", label: "Date", sortable: true },
                { key: "invoice_ref", label: "Invoice Ref" },
                { key: "merchant_invoice_ref", label: "Merchant Invoice Ref" },
                { key: "customer_name", label: "Customer Name",sortable: true },
                { key: "status", label: "Status" },
                { key: "due_date", label: "Due Date",sortable: true },
                { key: "total_amount", label: "Total Amount" },
                { key: "last_reminder_sent_at", label: "Last Reminder Sent",sortable: true },
                { key: "show_details", sortable: false, label: '' },
            ],
            from: "",
            to: "",
            filters: {
                status: [],
                source: "",
                from: "",
                to: ""
            },
            showTemplateDropDown:false,
            pdf_link:"",
            merchant_ref:"",
            sorting_data: {
                "sort_by_key": "",
                "order": ""
            },
            showImportInvoices: false,
            uploadedFile: null,
            loading: false,
            showImportSucess: false,
            successCount: 0,
            failedCount: 0,
            failedReportUrl: ""
        }
    },
    computed: {
        invoicesDataFromStore() {
             return this.$store.state.invoices.content || [];
        },
        invoicesTemplatesDataFromStore() {
             return this.$store.state.invoices.templateContent || [];
        },
        rows() {
            return this.$store.state.invoices.total_elements;
        },
        userPermissions() {
            return this.$store.getters["auth/getUserPermissionsList"];
        },

        userRoles() {
            return this.$store.getters['auth/getUserRolesList'];
        },
        merchantListDataFromStore() {
            return this.$store.state.masterMerchant.content;
        },
        isMasterMerchant() {
            return this.$store.state.auth.currentUser.is_master_merchant;
        },
        lastCurrency() {
            return this.invoicesDataFromStore.length > 0 ? this.invoicesDataFromStore[0].currency : "";
        },

        lastCountry() {
            return this.invoicesDataFromStore.length > 0 ? this.invoicesDataFromStore[0].countryCode : "";
        },
        isGlobalUser() {
            return this.$store.state.auth.currentUser.is_global_user;
        },

        subUserList() {
            return this.$store.state.accounts.subUserData || [];
        },
        sourceList() {
            return this.$store.state.paymentlinks.sourceList;
        },
    },
    created() {
        this.$store.dispatch(`paymentlinks/${FETCH_SOURCE_LIST}`);
        if(this.isMasterMerchant){
            this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
                pageIndex: 1,
                pagesize: 10,
                filters: { source: "default" },
            });
        }else{
            if(this.isGlobalUser) {
                this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
            }
            this.$store.dispatch(`invoices/${FETCH_INVOICES_TEMPLATES}`);
        }
    },
    filters: {
        date(value) {
            return moment(value).format("DD MMM YYYY");
        },
        time(value) {
            return 'at ' + moment(value).format("hh:mm A");
        },
    },
    methods: {
        fetchInvoices() {
            let filterTosend = this.getFiltersDetails();
            const payload = {
                page: this.currentPage,
                pagesize: this.perPage,
                to: this.filters.to,
                from: this.filters.from,
                filters: filterTosend,
                sorting_data: this.sorting_data
            };
            if(this.isMasterMerchant) {
                payload.merchant_keys = this.merchant_uuids.map(function (item) {
                return item.key;
                });
            }
            this.$store.dispatch(`invoices/${FETCH_INVOICES}`, {
                payload: payload,
                isMasterMerchant: this.isMasterMerchant,
            });
        },
        validateState(name, key) {
            const { $dirty, $error } = key ? this.$v.form[key][name] : this.$v.form[name];
            return $dirty ? !$error : null;
        },
        create_new_template_button(){
            this.$v.form.$touch();
              if (this.$v.form.$anyError) {
                return;
            }
            this.$router.push({name: 'Create Invoice Template', query: { tempName: this.form.template_name }});
        },
        delete_template(val){
            this.$store.dispatch(`invoices/${DELETE_INVOICE_TEMPLATE}`, val)
                .then((response) => {
                    console.log(response);
                    this.$notify({
                        type: "success",
                        text: response.message ? response.message : "Invoice Deleted Successfully",
                        closeOnClick: true,
                    });
                    this.$refs['delete-invoice-modal'].hide();
                    this.$store.dispatch(`invoices/${FETCH_INVOICES_TEMPLATES}`);
                })
                .catch((error) => {
                    // error
                    console.log("error", error);
                    this.$notify({
                        type: "error",
                        text: 'Failed To Detele Invoice.',
                        closeOnClick: true,
                    });
            });
        },
        routeToCreateInvoice(val){
             this.$router.push({name: 'Invoices Create', query: { templateRef: val }});
        },
        routeToEditTemplate(tempName, val){
            this.$router.push({name: 'Create Invoice Template', query: { tempName: tempName, templateRef: val }});
        },
        getFiltersDetails(){
            this.filters.from = "";
            this.filters.to = "";

            if(this.from) {
                this.filters.from = new Date(moment(this.from, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')); 
                this.filters.from =this.filters.from.toISOString();
            } else {
                this.filters.from = new Date(moment('2018-01-01T00:00:00.000').format('YYYY-MM-DD HH:mm:ss'))
                this.filters.from =this.filters.from.toISOString();
            }
            if(this.to) {
                this.filters.to = new Date(moment(this.to, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'))
                this.filters.to =this.filters.to.toISOString();
            } else {
                this.filters.to = new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
                this.filters.to =this.filters.to.toISOString();
            }

            let extrafilters = {};
            if(this.searchBy.value !== ""){
                if(this.searchBy.key == "Invoice Ref") {
                extrafilters['invoice_ref'] = this.searchBy.value.trim() 
                }else if(this.searchBy.key == "Merchant Invoice Ref") {
                extrafilters['merchant_invoice_ref'] = this.searchBy.value.trim()
                }else if(this.searchBy.key == "Description") {
                extrafilters['description'] = this.searchBy.value.trim()
                }
                else if(this.searchBy.key == "Customer Name") {
                extrafilters['customer_name'] = this.searchBy.value.trim()
                } 
                else if(this.searchBy.key == "Customer Email") {
                extrafilters['customer_email_address'] = this.searchBy.value.trim()
                } 
                else if(this.searchBy.key == "Customer Phone"){
                extrafilters['customer_phone_number'] = this.searchBy.value.trim()
                }
            }
            if(this.isGlobalUser && this.createdByFilter) {
                this.filters.created_by = this.createdByFilter.uuid;
            } else {
                delete this.filters.created_by;
            }
            let filtersTosend = {...this.filters,...extrafilters};
            return filtersTosend
        },
        dateChanged(value) {
            this.from = value[0];
            this.to = value[1];

            this.fetchInvoices();
        },
        resetAdvanceFilters() {
            this.filters = {
                from: "",
                to: "",
                status:[],
                source:"",
            };
            this.createdByFilter = null;
            this.fetchInvoices();
            this.$root.$emit('bv::toggle::collapse', 'invoice-filter-collapse')
        },
        openInvoiceDetailsPage(pageRef, submerchant_key) {
            this.$router.push({path: '/invoices/invoices-details', query: { ref: pageRef, key: this.isMasterMerchant ? submerchant_key : '' }});
        },
        templateDropDown(){
            this.showTemplateDropDown = !this.showTemplateDropDown;
        },
        exportInvoicesInExcel() {
            let filterTosend = this.getFiltersDetails();
            const payload = {
                page: this.currentPage,
                pagesize: this.perPage,
                to: this.filters.to,
                from: this.filters.from,
                filters: filterTosend,
                sorting_data: this.sorting_data
            };
            if(this.isMasterMerchant) {
                payload.merchant_keys = this.merchant_uuids.map(function (item) {
                    return item.key;
                });
            }
            this.$store.dispatch(`invoices/${EXPORT_INVOICES}`, {
                payload: payload,
                isMasterMerchant: this.isMasterMerchant,
            });
        },
        hideChooseTemplateModal() {
            this.$refs['modal-2'].hide();
        },
        showChooseTemplateModal(){
            this.showTemplateDropDown = false;
            this.$refs['modal-2'].show();
        },
        showDeleteTemplateModal(val){
           this.deteleTemplateData = val;
           this.$refs['delete-invoice-modal'].show();
        },
        preview_as_pdf(pdf,merchant_ref){
            this.pdf_link = pdf+'#toolbar=0';
            this.merchant_ref = merchant_ref;
            this.$refs['preview_payslip'].show();
        },
        downloadPdf(pdf) {
            window.open(pdf, '_blank');
        },
        sendReminder(ref){
            this.$store.dispatch(`invoices/${INVOICES_REMINDER}`, ref)
            .then((response) => {
                console.log(response);
                this.$notify({
                type: "success",
                text: "Reminder Successfully Sended",
                closeOnClick: true,
                });
            })
            .catch((error) => {
                // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: 'Failed To send Reminder.',
                    closeOnClick: true,
                });
            });
        },
        sortInvoices(item) {
            this.sorting_data.sort_by_key = item.sortBy;
            if(item.sortDesc) {
                this.sorting_data.order = "DESC";
            } else {
                this.sorting_data.order = "ASC";
            }
            this.fetchInvoices();
        },
        openImportInvoice() {
            this.showImportInvoices = true;
        },
        hideFileUploadModal() {
            this.showImportInvoices = false;
        },
        fileUpload() {
            let file = this.$refs.fileupload.files[0];
            if(Common.excelFileValdation(file, 50, true, /(\.xlsx)$/i)) {
                this.uploadedFile = file;
            }
        },
        removeSelectedFile() {
            this.uploadedFile = null;
            this.$refs.fileupload.value = null;
        },
        importInvoices() {
            const formData = new FormData();
            formData.append("bulk_invoice_file", this.$refs.fileupload.files[0]);
            this.loading = true;
            this.$store.dispatch(`invoices/${BULK_INVOICE_IMPORT}`, formData)
            .then((response) => {
                this.showImportInvoices = false;
                this.removeSelectedFile();
                this.showImportSucess = true;
                this.successCount = response.content.UploadSuccessCount;
                this.failedCount = response.content.UploadFailureCount;
                this.failedReportUrl = response.content.ErrorFileUrl;
            })
            .catch((error) => {
                // error
                console.log("error", error);
                const err = JSON.parse(error.message);
                Swal.fire("Error", err.message, "error");
                // this.$notify({
                //     type: "error",
                //     text: err.message,
                //     closeOnClick: true,
                // });
            })
            .finally(() => this.loading = false);
        },
        viewImportedInvoices() {
            this.currentPage = 1;
            this.from = "";
            this.to = "";
            this.filters = {
                status: [],
                source: "",
                from: "",
                to: ""
            };
            this.sorting_data = {
                "sort_by_key": "",
                "order": ""
            };
            this.fetchInvoices();
            this.showImportSucess = false;
        },
        downloadReport() {
            window.open(this.failedReportUrl, '_blank');
        }
    },
    directives: {
        'click-outside': {
        bind (el, binding, vnode) {
            const handleOutsideClick = (e) => {
            e.stopPropagation()
            const { handler, exclude } = binding.value
            const excludedEl = vnode.context.$refs[exclude]

            let clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)

            if (!el.contains(e.target) && !clickedOnExcludedEl && vnode.context.showTemplateDropDown) {
                vnode.context[handler]();
            }
            }
            document.addEventListener('click', handleOutsideClick)
            document.addEventListener('touchstart', handleOutsideClick)
        },
        }
    }
}
</script>

<template>
    <Layout :loading="loading">
        <div style="position: relative;" class="d-flex justify-content-end" v-if="!isMasterMerchant && (userPermissions.includes('create-invoices') || userRoles.includes('admin') || userRoles.includes('sub-admin'))">
            <button @click="templateDropDown" ref="templateDropDown-button" class="btn btn-primary btn-sm"
            style="padding: 12px 16px;font-weight: 400;font-size: 14px;line-height: 20px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M4.5 9H13.5M9 13.5V4.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Create New Invoice
            </button>
            <div
              v-click-outside="{
                exclude: 'templateDropDown-button',
                handler: 'templateDropDown'
              }"
            >
                <div v-if="showTemplateDropDown" class="select-template-container">
                    <router-link :to="{ name: 'Invoices Create', query: {lastCurrency, lastCountry} }">
                        <div class="select-template-section">
                            <div class="d-flex">
                                <div class="my-auto mr-2 p-2" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M5 10H15M10 15V5" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div style="margin-right: 10px;">
                                    <div class="name">New Invoice</div>
                                    <div class="sub-text">Create a new invoice.</div>
                                </div>
                            </div>
                            <div class="template-section-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g id="Iconsax/Linear/arrowdown2">
                                        <path id="Vector" d="M6.7124 3.06043L11.6024 7.95043C12.1799 8.52793 12.1799 9.47293 11.6024 10.0504L6.7124 14.9404" stroke="#FC6B2D" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                    <div @click="openImportInvoice" class="select-template-section">
                        <div class="d-flex">
                            <div class="my-auto mr-2 p-2" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_4335_6627)">
                                        <path d="M10.959 5.93268C13.359 6.13935 14.339 7.37268 14.339 10.0727V10.1593C14.339 13.1393 13.1457 14.3327 10.1657 14.3327H5.82568C2.84568 14.3327 1.65234 13.1393 1.65234 10.1593V10.0727C1.65234 7.39268 2.61901 6.15935 4.97901 5.93935M7.99901 9.99935V2.41268M10.2323 3.89935L7.99901 1.66602L5.76568 3.89935" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4335_6627">
                                        <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div style="margin-right: 10px;">
                                <div class="name">Import Invoices</div>
                                <div class="sub-text">Import your invoices all at once.</div>
                            </div>
                        </div>
                        <div class="template-section-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g id="Iconsax/Linear/arrowdown2">
                                    <path id="Vector" d="M6.7124 3.06043L11.6024 7.95043C12.1799 8.52793 12.1799 9.47293 11.6024 10.0504L6.7124 14.9404" stroke="#FC6B2D" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div @click="showChooseTemplateModal" class="select-template-section">
                        <div class="d-flex">
                            <div class="my-auto mr-2 p-2" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_4335_6622)">
                                        <path d="M14.6654 6.66732V10.0007C14.6654 13.334 13.332 14.6673 9.9987 14.6673H5.9987C2.66536 14.6673 1.33203 13.334 1.33203 10.0007V6.00065C1.33203 2.66732 2.66536 1.33398 5.9987 1.33398H9.33203" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M4.66797 8.66732H8.66797M4.66797 11.334H7.33464M14.668 6.66732H12.0013C10.0013 6.66732 9.33464 6.00065 9.33464 4.00065V1.33398L14.668 6.66732Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4335_6622">
                                        <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div style="margin-right: 10px;">
                                <div class="name">Choose Template</div>
                                <div class="sub-text">Select from your existing templates.</div>
                            </div>
                        </div>
                        <div class="template-section-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g id="Iconsax/Linear/arrowdown2">
                                    <path id="Vector" d="M6.7124 3.06043L11.6024 7.95043C12.1799 8.52793 12.1799 9.47293 11.6024 10.0504L6.7124 14.9404" stroke="#FC6B2D" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="isMasterMerchant">
            <div class="col-sm-12 col-md-9">
                <!-- do nothing -->
            </div>
            <div class="col-sm-12 col-md-3">
                <multiselect
                    v-model="merchant_uuids"
                    :options="merchantListDataFromStore"
                    @input="fetchInvoices"
                    placeholder="Search Merchants"
                    track-by="email_id"
                    label="email_id"
                    :searchable="true"
                    :multiple="true"
                ></multiselect>
            </div>
        </div>

        <filters class="mt-4 mb-3" @date-change="dateChanged" @toggle-filter="resetAdvanceFilters" />

        <div class="row">
            <div class="col-sm-12 col-md-12">
            <b-collapse id="invoice-filter-collapse">
                <b-card style="box-shadow: none;border-radius: 15px;border: 1px #DCDAD5 solid !important ;margin-bottom: 15px;" class="border border-light">
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-xl-4" v-if="isGlobalUser && !isMasterMerchant">
                        <label for="enddate" style="display: block;">{{ "Created By" }}</label>
                        <multiselect
                            v-model="createdByFilter"
                            placeholder="Search User"
                            :searchable="true"
                            :options="subUserList"
                            track-by="uuid"
                            label="email_address"
                            :multiple="false"
                        ></multiselect>
                    </div>
                    <div class="col-sm-12 col-md-6 col-xl-4">
                        <label for="enddate" style="display: block;">{{ "Invoice Status" }}</label>
                        <multiselect
                            v-model="filters.status"
                            placeholder="Invoice Status"
                            :searchable="true"
                            :options="['Sent', 'Overdue', 'Refunded', 'Paid', 'Created','Voided', 'Scheduled', 'Partially Refunded', 'Paid Late']"
                            :multiple="true"
                            class="sm-multiselect"
                        ></multiselect>
                    </div>
                    <div class="col-sm-12 col-md-6 col-xl-4">
                        <label for="enddate" style="display: block;">{{ "Source" }}</label>
                        <multiselect
                            v-model="filters.source"
                            placeholder="Search Source"
                            :searchable="true"
                            :options="sourceList"
                            :multiple="false"
                        ></multiselect>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
                    <div>
                    <b-btn variant="primary" @click="resetAdvanceFilters"  class="mr-2 cancel bg-white text-dark">Cancel</b-btn>
                    <b-btn variant="primary" class="btn-padding" @click="fetchInvoices">Save</b-btn>
                    </div>
                </div>
                </b-card>
            </b-collapse>
            </div>
        </div>

        <div class="d-flex justify-content-between my-3">
            <search-by :options="searchByOptions" v-model="searchBy" @search-by-clicked="fetchInvoices" />
            <download-btn @download-button-clicked="exportInvoicesInExcel" />
        </div>

        <!-- @sort-changed="sortTransactions" -->
        <b-table
            class="table-custom"
            :items="invoicesDataFromStore"
            :fields="fields"
            responsive="sm"
            @sort-changed="sortInvoices"
            sticky-header="50vh"
            style="height: 50vh;"
            show-empty
            no-local-sorting
            :busy="isFetching"
            :empty-text="$t('common.no-records')"
            hover
        >
            <template #table-busy>
                <b-spinner class="align-middle" :small="true"></b-spinner>
            </template>
            <template v-slot:head()="data">
                <span>
                    {{ $t(data.label) }}
                    <img v-show="data.field.sortable && sorting_data.sort_by_key !== data.column" class="ml-2" src="@/assets/images/arrow-3.svg" alt="sort_up">
                    <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'ASC'" class="ml-2" src="@/assets/images/sort_asc.svg" alt="sort_asc">
                    <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'DESC'" class="ml-2" src="@/assets/images/sort_desc.svg" alt="sort_desc">
                </span>
            </template>
            <template #cell(created_at)="row">
                <p class="table-date">{{ row.value | date }}</p>
                <p class="table-time">{{ row.value | time }}</p>
            </template>
             <template #cell(due_date)="row">
                 <div v-if="row.item.status == 'Overdue'">
                    Overdue
                        <b-dropdown class="overdue_reminder" size="sm" variant="white" dropright>
                            <template v-slot:button-content>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                                    <g id="vuesax/bold/notification-bing">
                                    <g id="notification-bing">
                                    <path id="Vector" d="M13.46 9.37288L12.7066 8.11954C12.54 7.84621 12.3933 7.31954 12.3933 6.99954V5.75288C12.3933 3.33288 10.4266 1.36621 8.01331 1.36621C5.59331 1.37288 3.62664 3.33288 3.62664 5.75288V6.99288C3.62664 7.31288 3.47997 7.83954 3.31997 8.11288L2.56664 9.36621C2.27997 9.85288 2.21331 10.4062 2.39331 10.8862C2.57331 11.3729 2.97997 11.7595 3.51331 11.9329C4.23331 12.1729 4.95997 12.3462 5.69997 12.4729C5.77331 12.4862 5.84664 12.4929 5.91997 12.5062C6.01331 12.5195 6.11331 12.5329 6.21331 12.5462C6.38664 12.5729 6.55997 12.5929 6.73997 12.6062C7.15997 12.6462 7.58664 12.6662 8.01331 12.6662C8.43331 12.6662 8.85331 12.6462 9.26664 12.6062C9.41997 12.5929 9.57331 12.5795 9.71997 12.5595C9.83997 12.5462 9.95997 12.5329 10.08 12.5129C10.1533 12.5062 10.2266 12.4929 10.3 12.4795C11.0466 12.3595 11.7866 12.1729 12.5066 11.9329C13.02 11.7595 13.4133 11.3729 13.6 10.8795C13.7866 10.3795 13.7333 9.83288 13.46 9.37288ZM8.49997 6.66621C8.49997 6.94621 8.27331 7.17288 7.99331 7.17288C7.71331 7.17288 7.48664 6.94621 7.48664 6.66621V4.59954C7.48664 4.31954 7.71331 4.09288 7.99331 4.09288C8.27331 4.09288 8.49997 4.31954 8.49997 4.59954V6.66621Z" fill="#FC6B2D"/>
                                    <path id="Vector_2" d="M9.88678 13.3397C9.60678 14.113 8.86678 14.6663 8.00012 14.6663C7.47345 14.6663 6.95345 14.453 6.58678 14.073C6.37345 13.873 6.21345 13.6063 6.12012 13.333C6.20678 13.3463 6.29345 13.353 6.38678 13.3663C6.54012 13.3863 6.70012 13.4063 6.86012 13.4197C7.24012 13.453 7.62678 13.473 8.01345 13.473C8.39345 13.473 8.77345 13.453 9.14678 13.4197C9.28678 13.4063 9.42678 13.3997 9.56012 13.3797C9.66678 13.3663 9.77345 13.353 9.88678 13.3397Z" fill="#FC6B2D"/>
                                    </g>
                                    </g>
                                </svg>
                            </template>
                        <b-dropdown-item >
                            <div>
                                <span style="color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 20px;">UNPAID INVOICE : {{ row.value | date }}</span>
                                <div style="font-size: 12px;line-height: 20px; margin-top: 12px;">
                                    <span style="display: block;margin-bottom: 20px;">Dear {{ row.item.customer_name }},</span>We're reaching out to you to inform about your unpaid invoice. Please find it attached.
                                </div>
                                <div @click="sendReminder(row.item.invoice_ref)" style="background: black;border-radius: 12px;color: #fff;border: 2px black solid;flex-direction: column;justify-content: center;align-items: flex-start;gap: 10px;display: inline-flex;margin-top: 20px;padding: 8px 16px;font-size: 14px;">Send Reminder</div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                 </div>
                <p v-else class="table-date">{{ row.value | date }}</p>
            </template>

            <!-- <template #cell(merchant_invoice_ref)="row">
                <span style="color: #FC6B2D;cursor: pointer;" @click="openInvoiceDetailsPage(row.item.invoice_ref, row.item.merchant_key)">{{row.value}}</span>
            </template> -->

            <template #cell(invoice_ref)="row">
                <span style="color: #FC6B2D;cursor: pointer;" @click="openInvoiceDetailsPage(row.item.invoice_ref, row.item.merchant_key)">{{row.value}}</span>
            </template>
            <template #cell(last_reminder_sent_at)="row">
                <p style="margin-bottom: 0px;" v-if="row.value" class="table-date">{{ row.value | date }}</p>
                <p style="margin-bottom: 0px;" v-else> N/A </p>
            </template>
            <template v-slot:cell(total_amount)="{ value, item }">
                {{ (value).toLocaleString('en-US', {style : 'currency', currency : item.currency}) }}
            </template>
            <template v-slot:cell(status)="row">
                <div
                class="badge font-size-12"
                style="padding: 6px 12px; border-radius: 8px; font-size: 12px; line-height: 20px;"
                :class="{
                    'sent-status':`${row.value}` === 'Sent',
                    'due-status':`${row.value}` === 'Overdue',
                    'draft-status':`${row.value}` === 'Draft',
                    'paid-late-status':`${row.value}` === 'Paid Late',
                    'badge-soft-danger':
                    `${row.value}` === 'Voided',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Scheduled',
                    'created-status': row.value === 'Created',
                    'badge-orange': row.value === 'Under Review',
                    'refunded': row.value === 'Refunded',
                    'partial-refund': row.value === 'Partially Refunded'
                }"
                >
                {{ row.value }}
                </div>
            </template>
            <template #cell(show_details)="row">
                <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
                    <div @click="downloadPdf(row.item.invoice_url)" style="padding: 10px 10px;border-radius: 8px;background: rgba(220, 218, 213, 0.30); height: 36px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g opacity="0.5">
                            <path d="M10.96 5.93359C13.36 6.14026 14.34 7.37359 14.34 10.0736V10.1603C14.34 13.1403 13.1467 14.3336 10.1667 14.3336H5.82665C2.84665 14.3336 1.65332 13.1403 1.65332 10.1603V10.0736C1.65332 7.39359 2.61999 6.16026 4.97999 5.94026" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 1.33301V9.91967" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.2333 8.43359L7.99994 10.6669L5.7666 8.43359" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                    </div>
                    <b-dropdown class="show_details" size="sm" variant="white" dropleft>
                        <template v-slot:button-content>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g id="Iconsax/Linear/more">
                                <path id="Vector" d="M6.6665 12.6667C6.6665 13.4 7.2665 14 7.99984 14C8.73317 14 9.33317 13.4 9.33317 12.6667C9.33317 11.9333 8.73317 11.3333 7.99984 11.3333C7.2665 11.3333 6.6665 11.9333 6.6665 12.6667ZM6.6665 3.33333C6.6665 4.06667 7.2665 4.66667 7.99984 4.66667C8.73317 4.66667 9.33317 4.06667 9.33317 3.33333C9.33317 2.6 8.73317 2 7.99984 2C7.2665 2 6.6665 2.6 6.6665 3.33333ZM6.6665 8C6.6665 8.73333 7.2665 9.33333 7.99984 9.33333C8.73317 9.33333 9.33317 8.73333 9.33317 8C9.33317 7.26667 8.73317 6.66667 7.99984 6.66667C7.2665 6.66667 6.6665 7.26667 6.6665 8Z" stroke="black" stroke-opacity="0.3"/>
                                </g>
                            </svg>
                        </template>
                        <b-dropdown-item-button v-if="!isMasterMerchant && (row.item.status == 'Scheduled' || row.item.status == 'Created')" @click="$router.push({ name: 'Invoices Update', query: { invoiceRef: row.item.invoice_ref } })">Edit</b-dropdown-item-button>
                        <b-dropdown-item-button v-if="!isMasterMerchant" @click="$router.push({ name: 'Invoices Create', query: { invoiceRef: row.item.invoice_ref } })">
                            Duplicate
                        </b-dropdown-item-button>
                        <b-dropdown-item-button v-if="row.item.status == 'Sent'" @click="sendReminder(row.item.invoice_ref)">Send Reminder</b-dropdown-item-button>
                        <b-dropdown-item-button  @click="preview_as_pdf(row.item.invoice_url, row.item.merchant_invoice_ref)">Preview as PDF</b-dropdown-item-button>
                        <!-- <b-dropdown-item-button  @click="deletePaymentLink(row.item.link_ref)">Delete Invoice</b-dropdown-item-button> -->
                    </b-dropdown>
                </div>
            </template>
        </b-table>

        <div class="d-flex justify-content-between">
            <per-page
                v-model="perPage"
                :currentPage="currentPage"
                :totalRows="rows"
                :tableRowsCount="1"
                @input="fetchInvoices"
            />
            <!-- invoicesDataFromStore.length -->
            <b-pagination
                class="my-auto"
                v-model="currentPage"
                :current-page="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                @input="fetchInvoices"
            ></b-pagination>
        </div>

        <b-modal
            id="modal-2"
            ref="modal-2"
            title=""
            hide-footer
            no-close-on-backdrop
            header-class="modal-header-custom"
            body-class="modal-body-custom-invoice"
            footer-class="modal-footer-custom"
            size="lg"
            centered
            scrollable
        >
            <template #modal-title>
                <div v-if="create_new_template" class="text-black" style="padding-left: 16px;font-size: 18px;margin-bottom: 5px;line-height: 24px;font-weight: 400;">Create New Template</div>
            </template>
            <div style="height: 100%;" v-if="!create_new_template">
                <div class="text-black" style="padding-left: 16px;font-size: 18px;margin-bottom: 5px;line-height: 24px;">Create or Choose an Invoice Template</div>
                <div style="padding-left: 16px;color: rgba(0, 0, 0, 0.5);font-size: 12px;font-weight: 325;line-height: 16px;">Create a new invoice template or choose from your existing ones.</div>
                <div style="margin-left: 16px;border-top: 1px solid rgba(220, 218, 213, 0.3);margin-top: 16px;"></div>
                <div  style="display: flex;flex-wrap: wrap;" 
                :class="{ 'template-section-parent': invoicesTemplatesDataFromStore.length < 1 }">
                    <div @click="create_new_template = true" class="template-section" style="text-align: center;background: rgba(220, 218, 213, 0.30);border: 1px #DCDAD5 dotted;box-shadow: unset;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <g filter="url(#filter0_d_3095_38234)">
                            <rect x="10" y="8" width="40" height="40" rx="8" fill="white"/>
                            </g>
                            <path d="M25 28H35M30 33V23" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                            <filter id="filter0_d_3095_38234" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_3095_38234"/>
                            <feOffset dy="2"/>
                            <feGaussianBlur stdDeviation="7.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3095_38234"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3095_38234" result="shape"/>
                            </filter>
                            </defs>
                        </svg>
                        <div>
                            <div class="name">Create New <br>Template</div>
                        </div>
                    </div>
                    <div v-for="(item, key) in invoicesTemplatesDataFromStore" :key="key">
                        <div @click="routeToCreateInvoice(item.template_ref)" class="template-section">
                            <div @click.stop="routeToEditTemplate(item.template_name, item.template_ref)" class="template-section-hover" style="position: absolute;right: 36px;top: 4px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                                    <g filter="url(#filter0_d_3095_38557)">
                                    <rect x="10" y="8" width="32" height="32" rx="4" fill="white"/>
                                    <rect x="10.5" y="8.5" width="31" height="31" rx="3.5" stroke="#DCDAD5"/>
                                    </g>
                                    <path d="M26.9435 17.7004L20.786 24.2179C20.5535 24.4654 20.3285 24.9529 20.2835 25.2904L20.006 27.7204C19.9085 28.5979 20.5385 29.1979 21.4085 29.0479L23.8235 28.6354C24.161 28.5754 24.6335 28.3279 24.866 28.0729L31.0235 21.5554C32.0885 20.4304 32.5685 19.1479 30.911 17.5804C29.261 16.0279 28.0085 16.5754 26.9435 17.7004Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.918 18.7871C26.2405 20.8571 27.9205 22.4396 30.0055 22.6496" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.25 31.5H32.75" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <defs>
                                    <filter id="filter0_d_3095_38557" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="2"/>
                                    <feGaussianBlur stdDeviation="5"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3095_38557"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3095_38557" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                            </div>
                            <div @click.stop="showDeleteTemplateModal(item)" class="template-section-hover" style="position: absolute;right: 0px;top: 4px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                                    <g filter="url(#filter0_d_3095_38560)">
                                    <rect x="10" y="8" width="32" height="32" rx="4" fill="white"/>
                                    <rect x="10.5" y="8.5" width="31" height="31" rx="3.5" stroke="#DCDAD5"/>
                                    </g>
                                    <path d="M32.75 19.485C30.2525 19.2375 27.74 19.11 25.235 19.11C23.75 19.11 22.265 19.185 20.78 19.335L19.25 19.485M23.375 18.7275L23.54 17.745C23.66 17.0325 23.75 16.5 25.0175 16.5H26.9825C28.25 16.5 28.3475 17.0625 28.46 17.7525L28.625 18.7275M31.1375 21.855L30.65 29.4075C30.5675 30.585 30.5 31.5 28.4075 31.5H23.5925C21.5 31.5 21.4325 30.585 21.35 29.4075L20.8625 21.855M24.7475 27.375H27.245M24.125 24.375H27.875" stroke="#E36464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <defs>
                                    <filter id="filter0_d_3095_38560" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="2"/>
                                    <feGaussianBlur stdDeviation="5"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3095_38560"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3095_38560" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="148" height="54" viewBox="0 0 148 54" fill="none">
                                <path d="M0 8C0 3.58172 3.58172 0 8 0H140C144.418 0 148 3.58172 148 8V54H0V8Z" fill="#DCDAD5" fill-opacity="0.3"/>
                                <path d="M25 13C25 10.7909 26.7909 9 29 9H119C121.209 9 123 10.7909 123 13V53H25V13Z" fill="white"/>
                                <rect x="91" y="17" width="24" height="3" rx="1" fill="#DCDAD5" fill-opacity="0.5"/>
                                <rect x="100" y="21" width="15" height="0.5" fill="#FC6B2D"/>
                                <rect x="33" y="26" width="24" height="2" rx="0.5" fill="#DCDAD5" fill-opacity="0.5"/>
                                <rect x="33" y="29" width="15" height="0.5" fill="#FC6B2D"/>
                                <rect x="33" y="30" width="12" height="0.5" fill="#DCDAD5" fill-opacity="0.5"/>
                                <rect x="33" y="46" width="82" height="2" rx="1" fill="#DCDAD5" fill-opacity="0.3"/>
                                <rect x="33" y="50" width="82" height="0.5" rx="0.25" fill="#DCDAD5" fill-opacity="0.3"/>
                                <rect x="33" y="35" width="82" height="9" rx="2" fill="#DCDAD5" fill-opacity="0.3"/>
                                <rect x="37" y="40" width="9" height="1" rx="0.5" fill="#FC6B2D"/>
                                <rect x="37" y="38" width="12" height="1" fill="white"/>
                                <rect x="91" y="26" width="24" height="2" rx="0.5" fill="#DCDAD5" fill-opacity="0.5"/>
                                <rect x="100" y="29" width="15" height="0.5" fill="#DCDAD5" fill-opacity="0.5"/>
                                <rect x="103" y="30" width="12" height="0.5" fill="#DCDAD5" fill-opacity="0.5"/>
                                <path d="M33.364 20V17.2H33.98V20H33.364ZM34.6158 20V17.856H35.2238V18.16C35.3638 17.98 35.5438 17.816 35.8518 17.816C36.3118 17.816 36.5798 18.12 36.5798 18.612V20H35.9718V18.804C35.9718 18.516 35.8358 18.368 35.6038 18.368C35.3717 18.368 35.2238 18.516 35.2238 18.804V20H34.6158ZM37.6818 20.016L36.8378 17.856H37.4818L37.9618 19.292L38.4458 17.856H39.0778L38.2338 20.016H37.6818ZM40.3592 20.048C39.6952 20.048 39.2032 19.556 39.2032 18.94V18.932C39.2032 18.316 39.6992 17.816 40.3672 17.816C41.0312 17.816 41.5232 18.308 41.5232 18.924V18.932C41.5232 19.548 41.0272 20.048 40.3592 20.048ZM40.3672 19.524C40.7152 19.524 40.9232 19.256 40.9232 18.94V18.932C40.9232 18.616 40.6952 18.34 40.3592 18.34C40.0112 18.34 39.8032 18.608 39.8032 18.924V18.932C39.8032 19.248 40.0312 19.524 40.3672 19.524ZM41.9754 17.62V17.08H42.6154V17.62H41.9754ZM41.9914 20V17.856H42.5994V20H41.9914ZM44.1824 20.048C43.5384 20.048 43.0664 19.552 43.0664 18.94V18.932C43.0664 18.32 43.5344 17.816 44.1904 17.816C44.5944 17.816 44.8464 17.952 45.0464 18.176L44.6744 18.576C44.5384 18.432 44.4024 18.34 44.1864 18.34C43.8824 18.34 43.6664 18.608 43.6664 18.924V18.932C43.6664 19.26 43.8784 19.524 44.2104 19.524C44.4144 19.524 44.5544 19.436 44.7024 19.296L45.0584 19.656C44.8504 19.884 44.6104 20.048 44.1824 20.048ZM46.413 20.048C45.769 20.048 45.293 19.596 45.293 18.94V18.932C45.293 18.32 45.729 17.816 46.353 17.816C47.069 17.816 47.397 18.372 47.397 18.98C47.397 19.028 47.393 19.084 47.389 19.14H45.897C45.957 19.416 46.149 19.56 46.421 19.56C46.625 19.56 46.773 19.496 46.941 19.34L47.289 19.648C47.089 19.896 46.801 20.048 46.413 20.048ZM45.889 18.76H46.805C46.769 18.488 46.609 18.304 46.353 18.304C46.101 18.304 45.937 18.484 45.889 18.76Z" fill="#DCDAD5" fill-opacity="0.7"/>
                            </svg>
                            <div>
                                <div class="name">{{item.template_name}}</div>
                                <div class="sub-text">Created on: {{ item.created_at | date }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="create_new_template" class="create-new-template">
                <div style="margin-left: 16px;border-top: 1px solid rgba(220, 218, 213, 0.3);margin-top: 16px;"></div>
                <div class="text-black" style="margin-top: 55px;padding-left: 16px;font-size: 18px;margin-bottom: 5px;line-height: 24px;">Write The Template Name</div>
                <div style="padding-left: 16px;color: rgba(0, 0, 0, 0.5);font-size: 12px;font-weight: 325;line-height: 16px;">Set the name of the template you’re going to create to identify.</div>
                <form style="margin-left: 16px;margin-top: 32px;" @submit.prevent autocomplete="off" >
                    <b-input-group style="gap: 8px;">
                        <b-form-group
                            class="flex-fill"
                            label-for="template_name"
                        >
                            <template #label>
                                Name<span class="text-danger">*</span>
                            </template>
                            <b-form-input :state="validateState('template_name')" id="template_name" placeholder="Please enter the name of your template." name="template_name" v-model="form.template_name"></b-form-input>
                            <b-form-invalid-feedback id="customer-name-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-input-group>
                </form>
                <div style="margin-bottom: 32px;margin-left: 16px;border-top: 1px solid rgba(220, 218, 213, 0.3);margin-top: 28px;"></div>
                <div class="d-flex" style="gap: 10px;justify-content: flex-end;">
                    <b-btn variant="primary" @click="create_new_template = false" style="border-radius: 8px;padding: 9px 16px;" class="bg-white text-dark">Cancel</b-btn>
                    <b-btn variant="primary" style="border-radius: 8px;padding: 9px 23px;" @click="create_new_template_button">Next</b-btn>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="delete-invoice-modal"
            ref="delete-invoice-modal"
            title=""
            hide-footer
            no-close-on-backdrop
            header-class="modal-header-custom"
            body-class="modal-body-custom-invoice-delete"
            footer-class="modal-footer-custom"
            centered
        >
            <div>
                <div class="text-black" style="padding-left: 16px;font-size: 24px;margin-bottom: 24px;line-height: 24px;">Delete Template</div>
                <div style="color: rgba(0, 0, 0, 0.70);font-size: 14px;padding-left: 16px;line-height: 20px;margin-bottom: 16px;">Are you sure you want to delete this template permanently?</div>
                <div style="border-radius: 12px;border: 2px rgba(220, 218, 213, 0.30) solid;display: flex; margin-bottom: 16px; align-items: center;">
                    <div style="padding: 16px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                            <path d="M0 12.8C0 8.31958 0 6.07937 0.871948 4.36808C1.63893 2.86278 2.86278 1.63893 4.36808 0.871948C6.07937 0 8.31958 0 12.8 0H25.2C29.6804 0 31.9206 0 33.6319 0.871948C35.1372 1.63893 36.3611 2.86278 37.1281 4.36808C38 6.07937 38 8.31958 38 12.8V25.2C38 29.6804 38 31.9206 37.1281 33.6319C36.3611 35.1372 35.1372 36.3611 33.6319 37.1281C31.9206 38 29.6804 38 25.2 38H12.8C8.31958 38 6.07937 38 4.36808 37.1281C2.86278 36.3611 1.63893 35.1372 0.871948 33.6319C0 31.9206 0 29.6804 0 25.2V12.8Z" fill="#DCDAD5" fill-opacity="0.3"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8107 28.3107C26.8107 28.3107 26.3713 28.75 25.75 28.75H12.25C12.25 28.75 11.6287 28.75 11.1893 28.3107C11.1893 28.3107 10.75 27.8713 10.75 27.25V10.75C10.75 10.75 10.75 10.1287 11.1893 9.68934C11.1893 9.68934 11.6287 9.25 12.25 9.25H21.25C21.4489 9.25 21.6397 9.32902 21.7803 9.46967L27.0303 14.7197C27.171 14.8603 27.25 15.0511 27.25 15.25V27.25C27.25 27.25 27.25 27.8713 26.8107 28.3107ZM25.75 27.25V15.5607L20.9393 10.75H12.25V27.25H25.75Z" fill="#FC6B2D"/>
                            <path d="M21.25 16H26.5C26.9142 16 27.25 15.6642 27.25 15.25C27.25 14.8358 26.9142 14.5 26.5 14.5H22V10C22 9.58579 21.6642 9.25 21.25 9.25C20.8358 9.25 20.5 9.58579 20.5 10V15.25C20.5 15.6642 20.8358 16 21.25 16Z" fill="#FC6B2D"/>
                            <path d="M16 20.5H22C22.4142 20.5 22.75 20.1642 22.75 19.75C22.75 19.3358 22.4142 19 22 19H16C15.5858 19 15.25 19.3358 15.25 19.75C15.25 20.1642 15.5858 20.5 16 20.5Z" fill="#FC6B2D"/>
                            <path d="M16 23.5H22C22.4142 23.5 22.75 23.1642 22.75 22.75C22.75 22.3358 22.4142 22 22 22H16C15.5858 22 15.25 22.3358 15.25 22.75C15.25 23.1642 15.5858 23.5 16 23.5Z" fill="#FC6B2D"/>
                        </svg>
                    </div>
                    <div>
                        <div style="font-size: 14px;line-height: 20px;">
                            {{deteleTemplateData.template_name}}
                        </div>
                        <div style="font-size: 12px;color: rgba(0, 0, 0, 0.50);line-height: 16px;font-weight: 325;">
                            Created on: {{ deteleTemplateData.created_at | date }}
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="gap: 10px;justify-content: flex-end;">
                    <b-btn variant="primary" @click="$refs['delete-invoice-modal'].hide();" style="padding: 12px 16px;" class="bg-white text-dark">Cancel</b-btn>
                    <b-btn variant="primary" style="padding: 12px 23px;" @click="delete_template(deteleTemplateData.template_ref)">Yes, Delete This Template!</b-btn>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="preview_payslip"
            ref="preview_payslip"
            title=""
            hide-footer
            no-close-on-backdrop
            header-class="modal-header-custom"
            body-class="modal-body-custom-invoice-pdf"
            footer-class="modal-footer-custom"
            size="lg"
        >
            <div class="">
                <div class="text-black" style="font-size: 14px;margin-bottom: 25px;line-height: 16px;letter-spacing: 0.70px;word-wrap: break-word;"># {{merchant_ref}}</div>
            </div>
            <div class="border w-100" style="height: 90%;" >
                <div class="d-flex h-100 justify-content-center">
                    <embed :src="pdf_link" v-if="pdf_link" type="application/pdf" width="100%" height="100%" />
                </div>
            </div>
        </b-modal>

        <b-modal
            v-model="showImportSucess"
            title=""
            hide-footer
            hide-header
            no-close-on-backdrop
            content-class="import_success_body"
            body-class="p-0"
            centered
        >
            <b-btn variant="white" class="p-0 d-block ml-auto" @click="() => this.showImportSucess = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g opacity="0.5" clip-path="url(#clip0_3027_37961)">
                        <path d="M2.83363 14.5016L14.5009 2.83434M14.5009 14.5016L2.83363 2.83434" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_3027_37961">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </b-btn>

            <div class="text-center">
                <div class="import_success_title">
                    <h1 style="font-size: 24px; font-weight: 400; letter-spacing: -0.36px; padding-top: 18px;">File Uploaded Successfully!</h1>
                    <p class="py-4 mb-0 font-14px" style="color: rgba(0, 0, 0, 0.70); font-weight: 350; line-height: 20px;">Here are the results of your file upload. You may also download the report to get further details</p>
                </div>

                <div class="imported_invoices_count d-flex" style="margin-bottom: 40px;">
                    <div class="d-flex flex-column justify-content-center" style="padding-right: 32px; gap: 8px;">
                        <div style="font-size: 18px; font-weight: 400; line-height: 24px;">{{ successCount }}</div>
                        <div style="color: rgba(0, 0, 0, 0.50); font-size: 14px; font-weight: 350; line-height: 20px;">Invoices Imported</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="57" viewBox="0 0 2 57" fill="none">
                        <path d="M1 0V57" stroke="#DCDAD5"/>
                    </svg>
                    <div class="d-flex flex-column justify-content-center" style="padding-left: 32px; gap: 8px;">
                        <div style="color: #DE3535; font-size: 18px; font-weight: 400; line-height: 24px;">{{ failedCount }}</div>
                        <div style="color: rgba(0, 0, 0, 0.50); font-size: 14px; font-weight: 350; line-height: 20px;">Invoices failed to import</div>
                    </div>
                </div>

                <b-btn variant="primary" class="mb-4" style="padding: 12px 24px; font-size: 14px; font-weight: 350; line-height: 20px; border-radius: 12px;" @click="downloadReport">
                    Download Report
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="ml-2">
                        <path d="M13.6997 7.41602C16.6997 7.67435 17.9247 9.21601 17.9247 12.591V12.6993C17.9247 16.4243 16.4331 17.916 12.7081 17.916H7.28307C3.55807 17.916 2.06641 16.4243 2.06641 12.6993V12.591C2.06641 9.24102 3.27474 7.69935 6.22474 7.42435M9.99974 1.66602V12.3993" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.7904 10.541L9.9987 13.3327L7.20703 10.541" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </b-btn>

                <b-btn variant="white" class="d-block mx-auto p-0 text-chai" style="font-size: 14px; font-weight: 400; line-height: 20px;" @click="viewImportedInvoices">
                    View Invoices
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="ml-2">
                        <path d="M9.6187 3.95312L13.6654 7.99979L9.6187 12.0465M2.33203 7.99979H13.552" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </b-btn>
            </div>
        </b-modal>

        <!-- Right Sidebar -->
        <div class="right-bar"
        style=""
        :class="{
            'active': showImportInvoices
        }">
        <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
        <div class="h-100 d-flex flex-column">
            <div class="pt-4 px-4 ml-2 d-flex justify-content-between">
            <div class="pt-3 mt-1">
                <p style="font-size: 18px; font-weight: 400; line-height: 24px;" class="mb-1">
                    Import Invoices
                </p>
                <p class="section-text" style="line-height: 16px;">Upload your files here to get started.</p>
            </div>
            <i class="mdi mdi-close noti-icon cursor-pointer mb-auto" style="position: relative;z-index: 1; font-size: 20px;" @click="hideFileUploadModal"></i>
            </div>

            <hr class="my-4" style="background: rgba(220, 218, 213, 0.30); border: none; height: 1px;">

            <div class="px-4 ml-2">

                <div class="position-relative file-input-draggable" style="margin-bottom: 12px;">
                    <input type="file" name="UploadFiles" accept=".xlsx" ref="fileupload" @change="fileUpload" class="file_upload">
                    <div class="d-flex flex-column align-items-center" v-if="uploadedFile">
                        <div style="border-radius: var(--8, 8px); background: #FFF; box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06); margin-top: 32px; margin-bottom: 12px;" class="p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048Z" fill="#FC6B2D"/>
                                <path d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.5 17.75H7.5C7.09 17.75 6.75 17.41 6.75 17C6.75 16.59 7.09 16.25 7.5 16.25H11.5C11.91 16.25 12.25 16.59 12.25 17C12.25 17.41 11.91 17.75 11.5 17.75ZM13.5 13.75H7.5C7.09 13.75 6.75 13.41 6.75 13C6.75 12.59 7.09 12.25 7.5 12.25H13.5C13.91 12.25 14.25 12.59 14.25 13C14.25 13.41 13.91 13.75 13.5 13.75Z" fill="#FC6B2D"/>
                            </svg>
                        </div>
                        <div class="d-flex" style="margin-bottom: 12px;">
                            <p class="font-14px mb-0" style="line-height: 20px; margin-bottom: 4px; max-width: 200px; overflow: hidden; text-overflow: ellipsis;">{{ uploadedFile.name }}</p>
                            <!-- <span class="my-auto" @click="removeSelectedFile">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="margin-left: 12px;">
                                    <path d="M14 3.98732C11.78 3.76732 9.54667 3.65398 7.32 3.65398C6 3.65398 4.68 3.72065 3.36 3.85398L2 3.98732M5.66667 3.31398L5.81333 2.44065C5.92 1.80732 6 1.33398 7.12667 1.33398H8.87333C10 1.33398 10.0867 1.83398 10.1867 2.44732L10.3333 3.31398M12.5667 6.09398L12.1333 12.8073C12.06 13.854 12 14.6673 10.14 14.6673H5.86C4 14.6673 3.94 13.854 3.86667 12.8073L3.43333 6.09398M6.88667 11.0007H9.10667M6.33333 8.33398H9.66667" stroke="#DE3535" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span> -->
                        </div>
                        <p class="font-14px" style="line-height: 16px; margin-bottom: 12px;">or</p>
                        <span class="font-14px text-white" style="border-radius: 12px; border: 1px solid #DCDAD5; background: #000; padding: 14px 16px; color: #FFF; margin-bottom: 32px;">
                            Replace File
                        </span>
                    </div>
                    <div class="d-flex flex-column align-items-center" v-else>
                        <div style="border-radius: var(--8, 8px); background: #FFF; box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06); margin-top: 32px; margin-bottom: 12px;" class="p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="m-1">
                                <g clip-path="url(#clip0_3027_37991)">
                                    <path d="M10.959 5.93268C13.359 6.13935 14.339 7.37268 14.339 10.0727V10.1593C14.339 13.1393 13.1457 14.3327 10.1657 14.3327H5.82568C2.84568 14.3327 1.65234 13.1393 1.65234 10.1593V10.0727C1.65234 7.39268 2.61901 6.15935 4.97901 5.93935M7.99901 9.99935V2.41268M10.2323 3.89935L7.99901 1.66602L5.76568 3.89935" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3027_37991">
                                    <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p class="font-14px mb-0" style="line-height: 20px; margin-bottom: 4px;">Drag & Drop your file here</p>
                        <p style="color: rgba(0, 0, 0, 0.30); font-size: 10px; line-height: 12px; margin-bottom: 24px;">Maximum file size: 50 Mb | File format: XLSX</p>
                        <span class="font-14px text-white" style="border-radius: 12px; border: 1px solid #DCDAD5; background: #000; padding: 14px 16px; color: #FFF; margin-bottom: 32px;">
                            Browse File
                        </span>
                    </div>
                </div>

                <p style="font-size: 14px; font-weight: 350; line-height: 20px; color: #000;">
                    Download a <a href="https://prod-sample-files.s3.ap-southeast-1.amazonaws.com/bulk_invoice_sample_final_with_templateref.xlsx" class="text-chai">sample XLSX file</a> and compare it to your import file to ensure it's perfectly formatted for the import.
                    <!-- <ul class="mt-2">
                        <li>
                            
                        </li>
                        <li>
                            <a href="https://prod-sample-files.s3.ap-southeast-1.amazonaws.com/bulk_invoice_sample_final_without_template.xlsx" class="text-chai">without template ref</a>
                        </li>
                    </ul> -->
                </p>
            </div>
            <div class="mt-auto mb-4">
                <hr class="my-4" style="background: rgba(220, 218, 213, 0.30); border: none; height: 1px;">
                <div class="d-flex justify-content-start px-3">
                    <b-button variant="white" @click="hideFileUploadModal" class="mr-2 bg-white text-dark border-dark cancel">{{ $t('button.cancel') }}</b-button>
                    <b-button variant="primary" @click="importInvoices" class="btn-padding" >{{ $t('button.submit') }}</b-button>
                </div>
            </div>
        </div>
        <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideFileUploadModal" class="rightbar-overlay"
        :class="{
            'active': showImportInvoices
        }">
        </div>
    </Layout>
</template>

<style>
    .due-status{
        background: rgba(252, 107, 45, 0.10);
        color: #FC6B2D;
    }
    .sent-status{
        background: rgba(57, 131, 172, 0.10);
        color: #3983AC;
    }
    .created-status{
        background: rgba(220, 218, 213, 0.50);
        color: rgba(0, 0, 0, 0.70);
    }
    .draft-status{
        background: rgba(220, 218, 213, 0.50);
        color: rgba(0, 0, 0, 0.70);
    }
    .paid-late-status{
        background:  rgba(155.09, 155.09, 245.68, 0.10);
        color: #7171E0;
    }
    .select-template-container{
        background: white;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        position: absolute;
        top: 57px;
        padding: 18px 12px;
        width: 360px;
        z-index: 4;
        right: 0px;
    }
    .select-template-section{
        border-radius: 8px;
        padding: 10px 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .select-template-section:hover {
        background: rgba(220, 218, 213, 0.30);
    }
    .select-template-section .name{
        line-height: 20px;
    }
    .select-template-section .sub-text{
        color: rgba(0, 0, 0, 0.50);
        font-size: 12px;
        font-weight: 325;
        line-height: 20px;
    }
    .select-template-section:hover .name{
        color: #FC6B2D;
    }
    .template-section-img{
        display: none;
    }
    .select-template-section:hover .template-section-img{
        display: block;
    }
    .modal-header-custom{
        border-bottom: none;
        padding-bottom: 0px;
    }
    .modal-body-custom-invoice{
        padding: 0px 30px 30px 14px;
        min-height: 600px;
        height: 600px;
        
    }
    .modal-body-custom-invoice-pdf{
        padding: 0px 30px 30px 30px;
        height: calc(100vh - 120px);
    }
    .modal-footer-custom{
        border-top: none;
        padding: 0px 55px 25px 55px;
    }
    .template-section{
        padding: 12px;
        cursor: pointer;
        margin-top: 16px;
        box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        border: 1px rgba(220, 218, 213, 0.30) solid;
        width: 172px;
        margin-left: 16px;
        position: relative;
    }
    .template-section .name{
        line-height: 16px;
        font-size: 12px;
        margin-top: 11px;
        height: 36px;
    }
    .template-section .sub-text{
        color: rgba(0, 0, 0, 0.30);
        font-size: 10px;
        font-weight: 325;
        line-height: 12px;
    }
    .template-section-hover{
        display: none;
    }
    .template-section:hover{
        background: rgba(252, 107, 45, 0.04); 
        box-shadow: 0px 0px 0px 4px rgba(252, 107, 45, 0.10); 
        border-radius: 12px;
        border: 1px #FC6B2D solid;
    }
    .template-section:hover .template-section-hover{
         display: block;
    }
    .show_details button{
        padding: 7px 9px;
        border-radius: 8px;
        background: rgba(220, 218, 213, 0.30);
        height: 36px;
    }
    .show_details ul{
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        transform: translate3d(-138px,40px,0)!important;
        z-index: 1!important;
    }
    .show_details ul button{
        padding: 12px 25px;
        border-radius: 0px;
        background-color: transparent;
        height: auto;
    }
    .overdue_reminder .dropdown-item{
        white-space: break-spaces;
        padding: 25px;
        height: auto !important;
    }
    .overdue_reminder button{
        padding: 0px;
        background: transparent;
        padding-left: 10px;
    }
    .overdue_reminder .dropdown-item:hover {
        color: inherit;
        border-right: 0px;
        background: #fff;
    }
    .overdue_reminder ul{
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        border: 1px rgba(220, 218, 213, 0.50) solid;
        padding: 0px;
        width: 245px;
    }
    ::v-deep #preview_payslip .modal-dialog{
        max-width: 700px;
    }
    .refunded{
        background: rgba(222, 53, 53, 0.10); 
        color: #DE3535;
    }
    .partial-refund{
        background: rgba(227, 179, 7, 0.10);
        color: #C59900;
    }
    .badge-soft-success{
        background: rgba(33, 150, 83, 0.10);
        color: #219653;
    }
    .badge-soft-danger{
        background: rgba(222, 53, 53, 0.10);
        color: #DE3535;
    }
    .file_upload{
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        cursor: pointer;
    }
    .file-input-draggable {
        border-radius: 12px;
        border: 1px dashed #DCDAD5;
        background: rgba(220, 218, 213, 0.30);
    }
    .file_upload::file-selector-button {
        display: none;
    }

    .import_success_body {
        padding: 32px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    }

    .import_success_title {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='471' height='346' viewBox='0 0 471 346' fill='none'%3E%3Cg filter='url(%23filter0_f_3027_37973)'%3E%3Ccircle cx='235.5' cy='110.5' r='60.5' fill='%23FC6B2D' fill-opacity='0.5'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_3027_37973' x='0' y='-125' width='471' height='471' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='87.5' result='effect1_foregroundBlur_3027_37973'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
        background-size: 121px 121px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .imported_invoices_count {
        border-radius: 12px;
        background: #F5F4F2;
        padding: 16px 30px;
    }

    .right-top-section{
        /* border-bottom: 1px solid #D9D9D9; */
        background-color: #F7F7F7;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        position: relative;
        z-index: 1;
    }
    .right-bar{
        width: 448px;
        right: -448px;
    }
    .right-bar.active{
        right: 0px;
    }
    .rightbar-overlay.active{
        display: block;
        position: fixed;
        z-index: 9997;
    }
    .right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
        border: 0.5px solid #00246414;
    }

    @media (max-width: 700px) {
        .right-bar{
            width: 100%;
            right: -100%;
        }
    }
    @media (max-width: 539px) {
        .right-bar{
            width: 320px;
            right: -320px;
        }
    }
</style>
<style lang="scss" scoped>
    ::v-deep form, .modal-body {
        input {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
        }
        input::placeholder {
            color: rgba(0, 0, 0, 0.30);
            opacity: 1; /* Firefox */
            font-weight: 325;
        }
        label {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
        }
        .form-control {
            border-radius: 12px;
            border: 1px solid rgba(220, 218, 213, 0.50);
            height: 60px;
            padding: 13px 16px;
        }
    }
    .template-section-parent{
        height: calc(100% - 40px);
        align-items: center;
        justify-content: center;
        .template-section{
            padding: 39px;
            margin-top: 0px;
            width: 226px;
            margin-left: 0px;
            .name{
                font-size: 14px;
            }
        }
    }
</style>